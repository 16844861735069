import React, { useEffect, useState } from "react"
import { AvatarGroup, Chip, Grid, Typography } from "@mui/material"
import * as Styled from "./Kruzhok.styles"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { LocationChip } from "./LocationChip"
import { stringifyDates } from "../util/util"
import { axiosInstance } from "../../../shared/api/axiosInstance"
import { IKruzhok } from "../models/Kruzhok.types"

export const KruzhokCard = ({ kruzhok }: { kruzhok: IKruzhok }) => {
  const [onHover, setOnHover] = useState(false)
  const [organization, setOrganization] = useState<null | { website: string; icon_logo: string }>(null)
  const [kruzhokProps, setKruzhokProps] = useState(kruzhok.template_props)

  useEffect(() => {
    axiosInstance
      .get(`/organizations/${kruzhok.owner}/`)
      .then((res: { data: { website: string; icon_logo: string } }) => {
        setOrganization(res.data)
      })
  }, [kruzhok])

  function goToEvent() {
    window.location.href = `/event/${kruzhok.slug}`
  }

  const handleMouseEnter = () => {
    setOnHover(true)
  }

  const handleMouseLeave = () => {
    setOnHover(false)
  }

  return (
    <Grid item xs={12} md={6} lg={4} sx={{ marginTop: "24px" }}>
      <Styled.KruzhokCard
        sx={{
          pointerEvents: kruzhok.visibility === "visible" ? "initial" : "none",
          opacity: kruzhok.visibility === "visible" ? 1 : 0.5,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "280px",
          }}
        >
          <Styled.ImageOfWebinar
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={goToEvent}
            sx={{
              cursor: "pointer",
              backgroundColor: String(kruzhokProps.main_screen.backgroundColor),
              backgroundImage: `url(${kruzhokProps?.main_screen?.Image?.url || ""})`,
              backgroundSize: onHover ? "70%" : "60%",
              borderTopRightRadius: 3,
              borderTopLeftRadius: 3,
            }}
          >
            <Chip
              sx={{ backgroundColor: "#FFF" }}
              label={
                <Typography variant={"caption"} fontWeight={"medium"}>
                  Кружок
                </Typography>
              }
            />
            {!!kruzhok.tags_info &&
              kruzhok.tags_info.map(e => (
                <Chip
                  sx={{ backgroundColor: "#FFF" }}
                  label={
                    <Typography variant={"caption"} fontWeight={"medium"}>
                      {e.value}
                    </Typography>
                  }
                />
              ))}
            {!!kruzhok.template_props.location_badge && (
              <LocationChip location={String(kruzhok.template_props.location_badge)} />
            )}
            {!!kruzhok.template_props.text_badge && (
              <Chip
                sx={{ backgroundColor: "#FFF" }}
                label={
                  <Typography variant={"caption"} fontWeight={"medium"}>
                    {String(kruzhok.template_props.text_badge)}
                  </Typography>
                }
              />
            )}
          </Styled.ImageOfWebinar>
        </div>
        <Styled.CardContent>
          <Typography
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={goToEvent}
            variant={"h3"}
            sx={{
              color: onHover ? "#1565C0" : "",
              cursor: "pointer",
              width: "100%",
            }}
          >
            {kruzhok?.title}
          </Typography>
          <Styled.DescriptionAndMessageDiv>
            <Typography
              sx={{ alignText: "flex-start", width: "100%" }}
              variant={"body1"}
              fontWeight={"medium"}
              fontSize={"small"}
            >
              {kruzhok?.subtitle}
            </Typography>
          </Styled.DescriptionAndMessageDiv>
        </Styled.CardContent>
        <Styled.FooterWrapper>
          <Styled.CardFooter>
            <Styled.FooterItem>
              <AccountCircleRoundedIcon sx={{ color: "#858585" }} />
              <Styled.FooterTypography variant={"caption"}>{kruzhok.participants_num}</Styled.FooterTypography>
            </Styled.FooterItem>
            {kruzhok?.start?.length > 0 && (
              <>
                <Styled.FooterItem>
                  <CalendarMonthIcon sx={{ color: "#858585" }} />
                  <Styled.FooterTypography variant={"caption"}>
                    {stringifyDates(kruzhok.start.split("T")[0])} - <br />
                    {stringifyDates(kruzhok.finish.split("T")[0])}
                  </Styled.FooterTypography>
                </Styled.FooterItem>
              </>
            )}
            {!!organization && (
              <AvatarGroup max={3} sx={{ marginLeft: "auto" }}>
                <Styled.CompanyAvatar
                  src={organization.icon_logo}
                  onClick={() => window.open(organization.website, "_blank")}
                />
              </AvatarGroup>
            )}
          </Styled.CardFooter>
        </Styled.FooterWrapper>
      </Styled.KruzhokCard>
    </Grid>
  )
}
