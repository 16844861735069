import React, { useEffect, useRef, useState } from "react"
import * as Styled from "./ui/Catalog.styles"
import { SearchBox } from "./ui/SearchBox"
import { fetchCourseList, fetchTags } from "../../entities/Course"
import { Tags } from "./ui/Tags"
import { CourseTypes } from "./ui/CourseTypes"
import { ICourse } from "../../entities/Course/api/course.types"
import { WebinarCard } from "../../entities/Webinar"
import VisibilitySensor from "react-visibility-sensor"
import { CircularProgress, Grid, Typography } from "@mui/material"
import { tab_types_mapper } from "./static/CatalogStatic"
import { CoursesNotFound } from "./ui/CoursesNotFound"
import { EventItem, IEvent } from "../../entities/Event"
import { NewCourses } from "./ui/NewCourses"
import { fetchOrgTags } from "../../entities/Course/api/course"
import { Button } from "../../shared/ui"
import { KruzhokCard } from "../../entities/Kruzhok/ui/KruzhokCard"

export const Catalog = ({
  tag,
  organization,
  type = "catalog",
  handleChangeRef,
  index,
  toCatalogButtonText = null,
}: {
  toCatalogButtonText?: string | null
  index?: number
  tag?: string
  organization: number | null
  type?: "catalog" | "landing"
  handleChangeRef?: (ref: any, index: number) => void
}) => {
  const status: string | null = localStorage.getItem("user_status")
  const [tags, setTags] = useState<
    {
      id: number
      value: string
    }[]
  >([])
  const [courseType, setCourseType] = useState(0)
  const [searchString, setSearchString] = useState<string>("")
  const [currentTags, setCurrentTags] = useState<string[]>(!!tag ? [tag] : [])
  const [courses, setCourses] = useState<IEvent[]>([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [abortController, setAbortController] = useState(new AbortController())
  const ref = useRef<any>(null)

  useEffect(() => {
    if (!!handleChangeRef) handleChangeRef(ref, index || 0)
  }, [ref])

  useEffect(() => {
    if (!!organization) {
      fetchOrgTags(organization).then(res => {
        setTags(
          res.data as {
            id: number
            value: string
          }[]
        )
      })
    } else {
      fetchTags().then(res => {
        setTags(
          res.data as {
            id: number
            value: string
          }[]
        )
      })
    }
    //loadFirst()
  }, [])

  function loadFirst() {
    setIsLoading(true)
    abortController.abort() // Отменяем предыдущий запрос
    const newAbortController = new AbortController()
    setAbortController(newAbortController) // Обновляем состояние с новым контроллером

    const { signal } = newAbortController // Получаем сигнал нового контроллера

    const params = {
      exclude_hidden: !(status === "admin" || status === "moderator"),
      page: 1,
      tags: currentTags.length > 0 ? currentTags.join(",") : undefined,
      type: courseType === 0 ? undefined : tab_types_mapper[courseType],
      title__icontains: searchString,
      owner: !!organization ? organization : undefined,
    }

    fetchCourseList(params, signal)
      .then(
        (res: {
          data: {
            results: IEvent[]
            next: string | null
          }
        }) => {
          setCourses(res.data.results.filter(e => e.type !== "course"))
          if (!!res.data.next) {
            setPage(2)
            setHasNextPage(true)
          } else {
            setHasNextPage(false)
          }
        }
      )
      .catch(err => {
        setCourses([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function handleScrolledToBottom(isBottomVisible: boolean) {
    if (hasNextPage && isBottomVisible && !isLoading) {
      abortController.abort() // Отменяем предыдущий запрос
      const newAbortController = new AbortController()
      setAbortController(newAbortController) // Обновляем состояние с новым контроллером
      const { signal } = newAbortController // Получаем сигнал нового контроллера

      setIsLoading(true)
      const params = {
        exclude_hidden: !(status === "admin" || status === "moderator"),
        type: courseType === 0 ? undefined : tab_types_mapper[courseType],
        page: page,
        title__icontains: searchString,
        tags: currentTags.length > 0 ? currentTags.join(",") : undefined,
        owner: !!organization ? organization : undefined,
      }
      fetchCourseList(params, signal)
        .then(
          (res: {
            data: {
              results: IEvent[]
              next: string | null
            }
          }) => {
            setCourses(prev => [...prev, ...res.data.results.filter(e => e.type !== "course")])
            if (!!res.data.next) {
              setPage(page + 1)
              setHasNextPage(true)
            } else {
              setHasNextPage(false)
            }
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    setCourses([])
    setIsLoading(true)
    setTimeout(() => {
      loadFirst()
    }, 1000)
  }, [courseType])

  useEffect(() => {
    setCourses([])
    loadFirst()
  }, [currentTags])

  useEffect(() => {
    setCourses([])
    loadFirst()
  }, [searchString])

  return (
    <Styled.CatalogMainDiv
      sx={type === "catalog" ? { marginBottom: "64px", marginTop: "75px" } : { marginTop: "48px" }}
      ref={ref}
    >
      <SearchBox searchString={searchString} setSearchString={setSearchString} />
      <Tags tags={tags} currentTags={currentTags} setCurrentTags={setCurrentTags} />
      <CourseTypes tab={courseType} setTab={setCourseType} />
      {!isLoading && courses.length === 0 && <CoursesNotFound tab={courseType} setTab={setCourseType} />}
      {courses.length > 0 && (
        <VisibilitySensor onChange={handleScrolledToBottom} partialVisibility={"bottom"}>
          <div style={{ minHeight: "40vh" }}>
            {courses
              .filter(e => e.type === "event")
              .slice(0, type === "landing" ? (courseType === 0 ? 3 : 4) : courses.length)
              .map(e => (
                <EventItem props={e} />
              ))}
            <Grid container spacing={6}>
              {courses
                .filter(e => e.type === "webinar")
                .slice(0, type === "landing" ? (courseType === 0 ? 3 : 6) : courses.length)
                .map(e => (
                  //@ts-ignore
                  <WebinarCard key={e.id} webinar={{ ...e, template_props: JSON.parse(e.template_props) }} />
                ))}
            </Grid>
            <Grid container spacing={6}>
              {courses
                .filter(e => e.type === "kruzhok")
                .slice(0, type === "landing" ? (courseType === 0 ? 3 : 6) : courses.length)
                .map(e => (
                  //@ts-ignore
                  <KruzhokCard key={e.id} kruzhok={{ ...e, template_props: JSON.parse(e.template_props) }} />
                ))}
            </Grid>
          </div>
        </VisibilitySensor>
      )}
      {isLoading && (
        <Styled.IsLoadingMainDiv>
          <CircularProgress size={48} />
        </Styled.IsLoadingMainDiv>
      )}
      {type === "landing" && !!toCatalogButtonText && (
        <Styled.ButtonToCatalog>
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={() => (window.location.href = `/catalog/organization/${organization}`)}
          >
            {toCatalogButtonText}
          </Button>
        </Styled.ButtonToCatalog>
      )}
    </Styled.CatalogMainDiv>
  )
}
